import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
// import { FormattedMessage } from 'react-intl'
import { Text } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { SectionWithObjects } from '../components/section'
import Container from '../components/container'
import Hero from '../components/hero'
import NextStep from '../components/next-step'
import getTranslations from '../utils/getTranslations'

const Page = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { page, heroBackgroundImage, nextStepBackground } = data
  const {
    title, content, acf, polylang_translations: translations,
  } = page
  const {
    text_kam_dal: textKamDal,
    popisek_tlacitka_kam_dal: popisekTlacitkaKamDal,
    odkaz_kam_dal: odkazKamDal,
  } = acf

  return (
    <Layout
      locale={locale}
      pageTranslationLinks={getTranslations(locale, translations)}
    >
      <SEO title={title} />

      <Hero
        title={title}
        backgroundImage={heroBackgroundImage}
        backgroundPosition="50% 30%"
      />

      <SectionWithObjects>
        <Container>
          <Text
            sx={{
              color: 'white',
              '& h1, & h2, & h3, & h4': {
                mt: 3,
                mb: 3,
                color: 'gold',
                fontSize: 9,
                fontWeight: 'bold',
              },
              '& p, & ul, & ol': {
                mb: 4,
                lineHeight: 1.75,
              },
              '& a': {
                color: 'gold',
              },
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Container>
      </SectionWithObjects>

      <NextStep
        title={textKamDal || ''}
        link={odkazKamDal}
        label={popisekTlacitkaKamDal}
        backgroundImage={nextStepBackground}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageQuery($key: String!) {
    page: wordpressPage(slug: { eq: $key }) {
      ...PageData
      ...PageTranslations
      acf {
        ...NextStep
      }
    }
    heroBackgroundImage: file(relativePath: { eq: "tym-opice.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nextStepBackground: file(relativePath: { eq: "pivo-v-ruce.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
